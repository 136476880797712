import './reset.css';
import './variable.css';
import './style.css';
import './inputbox.css';
import './responsive.css';

import { useEffect, useState } from "react";

import failed from "./assets/failed.svg";
import successful from "./assets/successful.svg";
import hidden from "./assets/hidden.png";
import logo from "./assets/logo.png";
import metamask from "./assets/metamask.svg";
import blocqx from "./assets/blocqx_logo.png";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./provideroptions.js";
import { toHex, truncateAddress } from "./utils";

import Contract_ABI from "./Contract_ABI.json";
const Contract_Address = "0x474A3D2e25Eca7448cF894DF7846d2B5F73229A7"

const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions: providerOptions, // required
  disableInjectedProvider: false
});

const m_network = "mainnet"
// mainnet = 1; goerli = 5
const m_chain = 1
const m_infura = "28dc8c9efec541cb8cfc383949b127f1"

function App() {

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [signer, setSigner] = useState();

  const [account, setAccount] = useState();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isHolder, setIsHolder] = useState(false);

  const [nftContract, setNftContract] = useState(null);

  const [totalMinted, setTotalMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [cost, setCost] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);

  let mintAmount = 1;

  const [paused, setPaused] = useState(true);
  const [publicMintEnabled, setPublicMintEnabled] = useState(false);

  // EFFECTS

  useEffect(() => {
    window.addEventListener('load', () => {
      document.getElementById('preloading').style.display = 'none';
    });

    web3Modal.clearCachedProvider();
    initializeApp();
    // if (web3Modal.cachedProvider) {
    //   console.log("Loaded cached", web3Modal.cachedProvider);
    //   connectWallet();
    // }
  }, []);

  useEffect(() => {
    if (provider?.on) {

      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        console.log("chainChanged", _hexChainId);
        switchNetwork();
      };

      const handleDisconnect = (err) => {
        console.log("disconnect", err);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    if (isWalletConnected) {
      loadAppData();
    }
  }, [isWalletConnected, account])

  // CONNECTION

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const signer = library.getSigner();
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();

      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setSigner(signer);

      if (network.chainId !== m_chain)
        await switchNetwork();

      setIsWalletConnected(true);

      document.querySelector("#inputButton").style.display = 'flex';
      document.querySelector(".disconnectButton").style.display = 'flex';

      console.log("Connected.")

    } catch (err) {
      console.log(err);
    }
  }

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();

    setAccount();
    setAccountBalance(0);
    setIsWalletConnected(false);
    setIsHolder(false);

    document.querySelector("#inputButton").style.display = 'none';
    document.querySelector(".disconnectButton").style.display = 'none';

    mintAmount = 1;

    console.log("Disconnected.")
  }

  // APP

  const initializeApp = async () => {
    const temp_provider = new ethers.providers.InfuraProvider(m_network, m_infura)
    const tempInstance = await new ethers.Contract(Contract_Address, Contract_ABI, temp_provider);

    // Paused
    tempInstance.functions.paused().then((response) => {
      let state = response[0];
      console.log("(INIT) Contract Paused: " + state);
      setPaused(state);
    });

    // PublicMintEnabled
    tempInstance.functions.publicMintEnabled().then((response) => {
      let state = response[0];
      console.log("(INIT) Public Mint Enabled: " + state);
      setPublicMintEnabled(state);
    });

    // TotalMinted
    tempInstance.functions.totalSupply().then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Total Minted: " + bal);
      setTotalMinted(bal);
    });

    // MaxSupply
    tempInstance.functions.currentMaxSupply().then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Max Supply: " + bal);
      setMaxSupply(bal);
    });

    // Cost
    tempInstance.functions.cost().then((response) => {
      let costInWei = parseInt(response[0]["_hex"]);
      console.log("(INIT) Mint cost (wei) " + costInWei)
      setCost(costInWei);
    });
  }

  const loadAppData = async () => {
    if (!isWalletConnected)
      return;
    try {
      console.log("Loading app data. Account:", account);

      // Account Balance
      library.getBalance(account).then((response) => {
        const bal = parseFloat(ethers.utils.formatEther(response)).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
        console.log("Account balance: " + bal + " ETH");
        setAccountBalance(response.toString());
      });

      let contractInstance = await new ethers.Contract(Contract_Address, Contract_ABI, signer);
      setNftContract(contractInstance);

      // Legionnaire Count
      contractInstance.functions.balanceOf(account).then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Legionnaire Count: " + bal);
        if(bal > 0)
          setIsHolder(true);
      });

      // Paused
      contractInstance.functions.paused().then((response) => {
        let state = response[0];
        console.log("Contract Paused: " + state);
        setPaused(state);
      });

      // PublicMintEnabled
      contractInstance.functions.publicMintEnabled().then((response) => {
        let state = response[0];
        console.log("Public Mint Enabled: " + state);
        setPublicMintEnabled(state);
      });

      // TotalMinted
      contractInstance.functions.totalSupply().then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Total Minted: " + bal);
        setTotalMinted(bal);
      });

      // MaxSupply
      contractInstance.functions.currentMaxSupply().then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Max Supply: " + bal);
        setMaxSupply(bal);
      });

      // Cost
      contractInstance.functions.cost().then((response) => {
        let costInWei = parseInt(response[0]["_hex"]);
        console.log("Mint cost (wei): " + costInWei)
        setCost(costInWei);
      });

    } catch (err) {
      let message = JSON.parse(err)["message"];
      console.log(message);
    }
  }

  // UTILITIES

  function enableProcessingStatus() {
    document.querySelector("#processingStatus").style.display = "block";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableFailedStatus() {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "block";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableSuccessfulStatus() {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "block";
  }

  const switchNetwork = async () => {
    await library.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(m_chain) }]
    });
    console.log("Switched network.");
    connectWallet();
  }

  const updateMintAmount = (counter) => {
    let currentValue = parseInt(document.getElementById("quantity").value);
    currentValue += parseInt(counter);
    if (currentValue <= 1) currentValue = 1;
    if (currentValue >= 5) currentValue = 5;

    mintAmount = currentValue;
    console.log({ mintAmount })

    document.getElementById("quantity").value = currentValue;
    document.getElementById("quantity").placeholder = currentValue;
  }

  // MINT FUNCTIONS

  const publicMint = async () => {

    if (nftContract !== undefined) {

      document.querySelector("#popup").style.display = "flex";
      document.querySelector("#status").innerHTML = "Transaction Processing...";
      document.querySelector("#message").innerHTML = "";
      enableProcessingStatus();

      mintAmount = parseInt(mintAmount);
      if (mintAmount < 1 || mintAmount > 5) mintAmount = 1;

      let totalCost = cost * mintAmount;
      let checksPassed = true;

      let errMessage = await nftContract.functions.totalSupply().then((response) => {
        let currentSupply = parseInt(response[0]["_hex"]);
        console.log("Current Supply: " + currentSupply);

        if (currentSupply + mintAmount > maxSupply) {
          console.log("Mint Supply Exceeded.");
          checksPassed = false;
          return "Mint Supply Exceeded.";
        }

        if (accountBalance < totalCost) {
          console.log("Insufficient Funds.");
          checksPassed = false;
          return "Insufficient Funds.";
        }
      });
      console.log({ checksPassed })
      if (!checksPassed) {
        document.querySelector("#status").innerHTML = "Transaction Failed!"
        document.querySelector("#message").innerHTML = errMessage;
        enableFailedStatus();
        return;
      }

      console.log("Minting " + mintAmount + " NFT(s), Cost: " + ethers.utils.formatEther(totalCost.toString()) + " ETH");

      try {
        await nftContract.mint(mintAmount, { from: account, value: totalCost.toString() }).then((res) => {
          enableSuccessfulStatus();
          document.querySelector("#status").innerHTML = "Transaction Sent!"
          document.querySelector("#message").innerHTML = "View Transaction Status on <a target='_blank' href='https://etherscan.io/tx/" + res.hash + "'>Etherscan</a>"
          console.log(res);

          setIsHolder(true);

          const queryParameters = new URLSearchParams(window.location.search)
          const relWalletAddress = queryParameters.get("ref")

          console.log({relWalletAddress})

          if (!(relWalletAddress == null)) {
            let requestOptions = {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify({walletAddress: relWalletAddress})
            };
  
            fetch('https://ksgdvek8cg.execute-api.us-east-1.amazonaws.com/production/affiliates', requestOptions)
              .then(response => response.json())
              .then(data => console.log({data}));
          }
        });
      } catch (err) {
        enableFailedStatus();
        document.querySelector("#status").innerHTML = "Transaction Failed!";
        document.querySelector("#message").innerHTML = "Your transaction failed, Error Code: " + err.code;
        console.error("------ TRANSACTION ERROR ------");
        console.error(err);
        console.error("------ TRANSACTION ERROR ------");
      }
    }
  }

  const NFTPayPopup = () => {
    const el = document.getElementById('nftpaypopup');
    if (el.style.display === "none")
      el.style.display = "flex";
    else
      el.style.display = "none";
  }

  return (
    <div className='main' >
      <div className='backgroundImage'></div>
      {/* PRELOADER */}
      <div className="preloading popup" id="preloading">
        <img src={logo} alt="" />
      </div>

      <div id='nftpaypopup' className="nftpaypopup" style={{ display: "none", zIndex: 100 }}>
        <div className="nftpayinner">
          <h1 className="closeNFTPay" onClick={() => { document.getElementById('nftpaypopup').style.display = 'none'; }}>x</h1>
          <iframe className="nftpay_iframe" title='NFTPay' src="https://payments.nftpay.xyz/iframe/iframe_pay/3ea20983-5266-4a94-9306-eb659d5871ec?"></iframe>
        </div>
      </div>

      {/* POPUP */}
      <div className="popup" id="popup">
        <div className="popupBox">
          <div className="popupHeading">
            <h1 className="statusHeading"></h1>
            <h1 className="closeX" onClick={() => { document.getElementById('popup').style.display = 'none'; }}>X</h1>
          </div>
          <div id="failedStatus" ><img src={failed} /></div>
          <div id="successfulStatus" ><img src={successful} /></div>
          <div id="processingStatus" ><div className="spinner"></div></div>
          <h1 id="status"></h1>
          <h2 id="message"></h2>
        </div>
      </div>

      {/* NAV BAR */}
      <nav>
        <div className="row">
          <div className="col">
            <a href='https://thexnft.io/'>
              <img id="navImg" src={logo} alt="THE X NFT" />
            </a>
          </div>
        </div>
      </nav>

      {/* MAIN SECTION */}
      <div className='mainScreen'>
        <section className='copy'>
          <h1>
          Join this exclusive community as a Genesis Legionnaire.
          </h1>
          <p>
          <br />
          You will be able to purchase your X NFT Digital Collectible Legionnaire using a Web3 Crypto wallet or simply use your credit card. Your Legionnaire purchase includes the following:
            <br /><br />
            ⁍ Commemorative X NFT Concept Art (see above).
            <br /><br />
            ⁍ The "First to Fight" medal, <span >Rare NFT Trait</span> - is only available to the first <span >1,500 Legionnaires</span>.
            <br /><br />
            ⁍ X NFT Meta Fort Pass for future access to events and experiences.
            <br /><br />
            ⁍ Access to play <span >The Quest</span> and win up to <span>$150,000</span> in cash & prizes.
            <br /><br />
            ⁍ Your unique <span >share-to-earn</span> affiliate link. Earn a FREE Legionnaire.*
            <br /><br />
            ⁍ Save over <span >$200</span> off the upcoming Public Mint price.
            <br /><br />
            <br /><br />
            <br /><br />
            <br /><br />
            **Refer to the Discord announcement for more details on the affiliate program.
          </p>
        </section>
        <div className='mintingSection'>
          <section className="mainSection">
            <div className="mainHeading" id="mainHeading">
              <h1>THE X GENESIS MINT</h1>
            </div>
            <div className="stats">
              <div className="subheading">
                <h2 className="title">
                  Supply
                </h2>
                <h2 className="value">
                  {/* {totalMinted} / {maxSupply - 10} */}
                  {maxSupply - 10 - totalMinted} left
                </h2>
              </div>
              <div className="subheading">
                <h2 className="title">
                  Price
                </h2>
                <h2 className="value">
                  ~ TBA
                  <br></br>
                  {/* <span >{ethers.utils.formatEther(cost.toString())} ETH</span> */}
                  
                </h2>
              </div>
            </div>
            {/* <div className="canMint" hidden={publicMintEnabled}>
              Mint is Paused.
            </div> */}
            {/* <div className="canMint">
              MINT TO COHORT: LEONIDAS
            </div> */}
            <div className="nftImage">
              <img id="mainNftImage" src={hidden} alt="The X NFT" />
              <div className="quantity buttons_added" id="inputButton">
                <input disabled={paused || !publicMintEnabled} type="button" value="-" className="minus" onClick={() => { updateMintAmount(-1) }} />
                <input disabled={paused || !publicMintEnabled}
                  type="number" step="1" min="1" max="5" name="quantity" id="quantity" value="1" placeholder="1" title="Qty" className="input-text qty text"
                  size="4" pattern="" inputmode="" />
                <input disabled={paused || !publicMintEnabled} type="button" value="+" className="plus" onClick={() => { updateMintAmount(1) }} />
              </div>
            </div>
            <a className="disconnectButton" onClick={() => { disconnect(); }}>
              Connected: {truncateAddress(account)}
            </a>
            {/* <button className="mintButton" hidden={isWalletConnected} onClick={() => { connectWallet(); }}> */}
            <button className="mintButton" hidden={isWalletConnected}>

              <div className="cryptoButton">
                {/* <img src={metamask} /> &nbsp; <h3>Connect Wallet</h3> */}
                {/* <img src={metamask} /> */}
                 &nbsp; <h3>Coming Soon</h3>


              </div>
            </button>
            <button className="mintButton" hidden={!isWalletConnected} disabled={!publicMintEnabled} onClick={() => { publicMint(); }}>
              <div className="cryptoButton">
                <img src={metamask} /> &nbsp; Mint
              </div>
            </button>
            {/* <button disabled={!publicMintEnabled} onClick={() => { NFTPayPopup(); }} className="mintButton" id="card"> Buy with Credit Card</button> */}
            {/* <button disabled={!publicMintEnabled} onClick={() => { NFTPayPopup(); }} className="mintButton" id="card"> Buy with Credit Card</button> */}
            {/* <h2 className='refereal' ><button hidden={!isWalletConnected || !isHolder} className='copybuttons' onClick={() => {navigator.clipboard.writeText(`https://www.mint.thexnft.io?ref=${account}`)}}>COPY AFFILIATE LINK</button> </h2> */}
            <div hidden={!(isWalletConnected && isHolder)}>
              <br/>
              <h2>Share your affilate link</h2>
              <p id='refLink'>https://www.mint.thexnft.io?ref={account}</p>
              <button onClick={() => {navigator.clipboard.writeText(`https://www.mint.thexnft.io?ref=${account}`)}}>COPY LINK</button>
            </div>
            <a href="https://blocqx.com/" target="_blank">
              <h3 className='nalikesFooter'>Powered By&nbsp; <img src={blocqx} /> </h3>
            </a>
          </section>
          <br/>

        </div>
      </div>
    </div>
  );
}
export default App;
